import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";

export default function Navbar() {
  const [nav, setNav] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [inWorksAndActivities, setInWorksAndActivities] = useState(false);
  const [inAboutAndContact, setInAboutAndContact] = useState(false);
  const [atHome, setAtHome] = useState(false);

  const menuItems = [
    { text: "home", link: "home" },
    { text: "works", link: "works" },
    { text: "activities", link: "activities" },
    { text: "about", link: "about" },
    { text: "contact", link: "contact" },
  ];

  const [burgerClass, setBurgerClass] = useState("burger-bar unclicked");
  const [menuClass, setMenuClass] = useState("menu hidden");
  const [isMenuClicked, setIsMenuClicked] = useState(false);

  const updateMenu = () => {
    if (!isMenuClicked) {
      setBurgerClass("burger-bar clicked");
      setMenuClass("burger-bar menu visible");
    } else {
      setBurgerClass("unclicked");
      setMenuClass("menu hidden");
    }
    setNav(!nav);
    setIsMenuClicked(!isMenuClicked);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY + window.innerHeight / 8;
    const worksOffset = document.getElementById("works").offsetTop;
    const activitiesOffset = document.getElementById("activities").offsetTop;
    const aboutOffset = document.getElementById("about").offsetTop;
    const contactOffset = document.getElementById("contact").offsetTop;

    setShowButton(scrollPosition > worksOffset);
    setInWorksAndActivities(scrollPosition >= worksOffset && scrollPosition < aboutOffset);
    setInAboutAndContact(scrollPosition >= aboutOffset && scrollPosition < contactOffset);
    setAtHome(scrollPosition < worksOffset);
  };

  return (
    <div className="flex items-center">
      {/* Mobile Menu */}
      {/* Overlay */}
      {nav && <div className="bg-black/80 fixed w-full h-screen z-10 top-0 left-0" onClick={updateMenu}></div>}

      {/* Side drawer menu */}
      <div
        className={`fixed top-0 right-0 xxs:w-[calc(85%-1rem)] sm:w-[calc(50%-1rem)] xl:w-[calc(30%-1rem)] h-[calc(100%-1rem)] z-10 duration-500 rounded-md shadow-xl m-2 ${
          nav ? "translate-x-0" : "translate-x-full"
        } ${
          (inWorksAndActivities || atHome) ? "bg-[#1A2130] text-[#F5EFE6]" : inAboutAndContact ? "bg-[#F5EFE6] text-[#1A2130]" : "bg-[#F5EFE6] text-[#1A2130]"
        }`}
        style={{ right: nav ? '0' : '-100%' }} // Ensure the menu is completely hidden when closed
      >
        <div className="xxs:pt-24 sm:pt-32 flex flex-col h-full justify-between"> {/* Flex container to position content */}
          <div className="py-4"> {/* Menu items */}
            <nav className="">
              <ul className="flex flex-col p-4">
                {menuItems.map(({ text, link }, index) => (
                  <div key={index} className="lg:py-4 xxs:py-2 sm:text-4xl xxs:text-2xl font-medium">
                    <li>
                      <Link
                        to={link}
                        smooth={true}
                        duration={1000}
                        className="cursor-pointer navbar-hover"
                        onClick={updateMenu}
                      >
                        <span>{text}</span>
                      </Link>
                    </li>
                  </div>
                ))}
              </ul>
            </nav>
          </div>
          <div className="xxs:px-10 sm:px-12 py-8">
            <div className=""> 
              <div className='sm:text-2xl font-bold pt-4'>
                emails
              </div>
              <ul>
                  <li className="xxs:py-1 sm:py-2">
                      <a href="hello@millaizha.com" className="email-navbar-hover xxs:text-sm sm:text-lg font-normal" data-after="hello@millaizha.com">
                          <span className=''>hello@millaizha.com</span>
                      </a>
                  </li>
                  <li >
                      <a href="mdvalencia3@up.edu.ph" className="email-navbar-hover xxs:text-sm sm:text-lg font-normal" data-after="mdvalencia3@up.edu.ph">
                          <span className=''>mdvalencia3@up.edu.ph</span>
                      </a>
                  </li>
              </ul>
            </div>
            <div>
              <ul className={`xxs:py-4 sm:py-6 flex flex-row menu-link xxs:text-sm sm:text-lg`}>
                <li className={`mr-4 border rounded-full px-[0.625] py-1 duration-500 ${
                  (inWorksAndActivities || atHome) ? "border-[#F5EFE6] hover:bg-[#F5EFE6] hover:text-[#1A2130]" : inAboutAndContact ? "hover:text-[#F5EFE6] border-[#1A2130] hover:bg-[#1A2130]" : "hover:text-[#F5EFE6] border-[#1A2130] hover:bg-[#1A2130]"
                }`}><a href="https://github.com/millaizha" target="_blank" className="xxs:p-4 lg:p-6">GitHub</a></li>
                <li className={`border rounded-full px-[0.625] py-1 duration-500 ${
                  (inWorksAndActivities || atHome) ? "border-[#F5EFE6]  hover:bg-[#F5EFE6] hover:text-[#1A2130]" : inAboutAndContact ? "hover:text-[#F5EFE6] border-[#1A2130] hover:bg-[#1A2130]" : "hover:text-[#F5EFE6] border-[#1A2130] hover:bg-[#1A2130]"
                }`}><a href="https://linkedin.com/in/millaizha" target="_blank"className="xxs:p-4 p-6" >LinkedIn</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <button
        className={`fixed top-12 right-12 text-white cursor-pointer ml-auto transition-opacity ${
          showButton ? 'opacity-100 ease-in duration-300 z-50' : 'opacity-0 ease-out duration-300 z-0'
        }`}
        onClick={updateMenu}
        disabled={!showButton}
      >
        <nav>
          <div
            className={`burger-menu absolute xxs:left-[-4em] xs:left-[-5em] md:left-[-6em] xxs:scale-50 xs:scale-75 md:scale-100 w-[6em] p-[1.75em] rounded-full transform transition duration-500 xxs:hover:scale-75 xs:hover:scale-90 md:hover:scale-110 ${
              (inWorksAndActivities || atHome) ? "bg-[#F5EFE6] hover:bg-[#FFFDD0] duration-500" : inAboutAndContact ? "bg-[#1A2130] hover:bg-[#102C57] duration-500" : "bg-[#1A2130] hover:bg-[#102C57] duration-500"
            }`}
          >
            <div className={`rounded-[0.5em] h-[0.3em] w-[2em] duration-500 ${burgerClass} ${(inWorksAndActivities || atHome) ? "bg-[#1A2130]" : "bg-[#F5EFE6]"}`}></div>
            <div className={`rounded-[0.5em] h-[0.3em] w-[2em] duration-500 ${burgerClass} ${(inWorksAndActivities || atHome) ? "bg-[#1A2130]" : "bg-[#F5EFE6]"}`}></div>
            <div className={`rounded-[0.5em] h-[0.3em] w-[2em] duration-500 ${burgerClass} ${(inWorksAndActivities || atHome) ? "bg-[#1A2130]" : "bg-[#F5EFE6]"}`}></div>
          </div>
        </nav>
      </button>
    </div>
  );
}
