import { Link } from "react-scroll";

export default function Anchor() {
    return (
        <div className="w-full flex justify-between xxs:p-4 xs:p-8 sm:p-10 md:p-14 xl:px-20 xl:py-10 z-10">
            {/* <div className="text-4xl font-bold text-[#153448]"> */}
            <div className="font-bold text-[#153448] xxs:text-xl xs:text-2xl sm:text-3xl md:text-4xl">
                mill
            </div>
            <div className="font-normal flex flex-col text-left justify-center xxs:text-sm xs:text-base sm:text-lg md:text-xl xl:text-2xl">
                <ul>
                    <li className="xl:py-1">
                    <Link to="home" smooth={true} duration={1000} className="anchor-hover cursor-pointer" data-after="home">
                            <span>home</span>
                        </Link>
                    </li>
                    <li className="xl:py-1">
                    <Link to="works" smooth={true} duration={1000} className="anchor-hover cursor-pointer" data-after="works">
                            <span>works</span>
                        </Link>
                    </li>
                    <li className="xl:py-1">
                    <Link to="activities" smooth={true} duration={1500} className="anchor-hover cursor-pointer" data-after="activities">
                            <span>activities</span>
                        </Link>
                    </li>
                    <li className="xl:py-1">
                    <Link to="about" smooth={true} duration={2000} className="anchor-hover cursor-pointer" data-after="about">
                            <span>about</span>
                        </Link>
                    </li>
                    <li className="xl:py-1">
                    <Link to="contact" smooth={true} duration={2500} className="anchor-hover cursor-pointer" data-after="contact">
                            <span>contact</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
}
