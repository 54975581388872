import React, { useEffect, useState } from 'react';
import './App.css';
import Home from './components/home';
import Works from './components/works';
import Activities from './components/activities';
import About from './components/about';
import Preloader from './components/preloader';
import { motion, useMotionValue, useSpring, AnimatePresence } from 'framer-motion';
import Lenis from 'lenis';
import { LoadProvider } from './LoadContext';

function App() {
  useEffect(() => {
    const lenis = new Lenis();

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  const [currentSection, setCurrentSection] = useState('home');
  const [cursorEnlarged, setCursorEnlarged] = useState(false);

  const cursorX = useMotionValue(-100);
  const cursorY = useMotionValue(-100);

  const springConfig = { damping: 100, stiffness: 800 };
  const cursorXSpring = useSpring(cursorX, springConfig);
  const cursorYSpring = useSpring(cursorY, springConfig);

  useEffect(() => {
    const moveCursor = (e) => {
      cursorX.set(e.clientX - 16);
      cursorY.set(e.clientY - 16);
    };

    window.addEventListener('mousemove', moveCursor);

    return () => {
      window.removeEventListener('mousemove', moveCursor);
    };
  }, []);

  useEffect(() => {
    const handleLoad = () => {
      setIsLoading(false);
      document.body.style.cursor = 'default';
      window.scrollTo(0, 0);
    };

    window.addEventListener('load', handleLoad);

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight / 4;
      const homeOffset = document.getElementById('home').offsetTop;
      const worksOffset = document.getElementById('works').offsetTop;
      const activitiesOffset = document.getElementById('activities').offsetTop;
      const aboutOffset = document.getElementById('about').offsetTop;

      if (scrollPosition < worksOffset) {
        setCurrentSection('home');
        setCursorEnlarged(false);
      } else if (scrollPosition < activitiesOffset) {
        setCurrentSection('works');
      } else if (scrollPosition < aboutOffset) {
        setCurrentSection('activities');
        setCursorEnlarged(false);
      } else {
        setCurrentSection('about');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleMouseEnter = () => {
      setCursorEnlarged(true);
  };

  const handleMouseLeave = () => {
    setCursorEnlarged(false);
  };

  return (
    <LoadProvider>
      <div className={`App ${currentSection}`}>
        <AnimatePresence mode="wait">
          {isLoading && <Preloader />}
        </AnimatePresence>
        <header className="App-header">
          <Home id="home" />
          <Works id="works" onHover={handleMouseEnter} onLeave={handleMouseLeave} />
          <Activities id="activities" />
          <About id="about" />
        </header>
      </div>
      <motion.div
        className={`fixed top-0 left-0 h-8 rounded-full pointer-events-none flex items-center justify-center z-50`}
        style={{
          backgroundColor: currentSection === 'home' || currentSection === 'about' ? '#102C57' : '#FFFDD0',
          width: cursorEnlarged ? 120 : 40,
          height: cursorEnlarged ? 120 : 40,
          transition: 'background-color 500ms, width 500ms, height 500ms',
          translateX: cursorXSpring,
          translateY: cursorYSpring,
        }}
      >
        <span className={`font-semibold text-xl duration-500 ${cursorEnlarged ? 'opacity-100' : 'opacity-0'}`}>view</span>
      </motion.div>
    </LoadProvider>
  );
}

export default App;
