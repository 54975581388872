import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Assembly from "../assets/assembly.png";
import C from "../assets/c.png";
import Cpp from "../assets/c++.png";
import Cs from "../assets/cs.png";
import CSS from "../assets/css.png";
import Dart from "../assets/dart.png";
import Firebase from "../assets/firebase.png";
import Flutter from "../assets/flutter.png";
import Godot from "../assets/godot.png";
import HTML from "../assets/html.png";
import Java from "../assets/java.png";
import JS from "../assets/js.png";
import MongoDB from "../assets/mongodb.png";
import MySQL from "../assets/mysql.png";
import NodeJS from "../assets/node.png";
import Python from "../assets/python.png";
import R from "../assets/r.png";
import ReactJS from "../assets/react.png";
import Unity from "../assets/unity.png";
import { useScroll, useTransform, motion } from 'framer-motion';
import { useRef } from 'react';
import { Contact } from "./contact";
import AnimText from './text';
import ScrollToTop from "react-scroll-up";
import { FaArrowUp } from "react-icons/fa6";

function AutoPlay() {
    const settings = {
        dots: false,
        arrows: false,
        pauseOnHover: false,
        pauseOnFocus: false,
        infinite: true,
        slidesToShow: 16,
        slidesToScroll: 1,
        autoplay: true,
        speed: 900,
        autoplaySpeed: 900,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                  slidesToShow: 8,
                  slidesToScroll: 1
                }
              },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1
              }
            },
          ]
    };

    return (
        <Slider {...settings}>
            <div>
                <img src={Assembly} style={{ width: 'auto', height: '5vh'}} />
            </div>
            <div>
                <img src={C} style={{ width: 'auto', height: '5vh'}} />
            </div>
            <div>
                <img src={Cpp} style={{ width: 'auto', height: '5vh'}} />
            </div>
            <div>
                <img src={Cs} style={{ width: 'auto', height: '5vh'}} />
            </div>
            <div>
                <img src={Dart} style={{ width: 'auto', height: '5vh'}} />
            </div>
            <div>
                <img src={Java} style={{ width: 'auto', height: '5vh'}} />
            </div>
            <div>
                <img src={Python} style={{ width: 'auto', height: '5vh'}} />
            </div>
            <div>
                <img src={R} style={{ width: 'auto', height: '5vh'}} />
            </div>
            <div>
                <img src={HTML} style={{ width: 'auto', height: '5vh'}} />
            </div>
            <div>
                <img src={CSS} style={{ width: 'auto', height: '5vh'}} />
            </div>
            <div>
                <img src={JS} style={{ width: 'auto', height: '5vh'}} />
            </div>
            <div>
                <img src={NodeJS} style={{ width: 'auto', height: '5vh'}} />
            </div>
            <div>
                <img src={ReactJS} style={{ width: 'auto', height: '5vh'}} />
            </div>
            <div>
                <img src={MongoDB} style={{ width: 'auto', height: '5vh'}} />
            </div>
            <div>
                <img src={MySQL} style={{ width: 'auto', height: '5vh'}} />
            </div>
            <div>
                <img src={Firebase} style={{ width: 'auto', height: '5vh'}} />
            </div>
            <div>
                <img src={Flutter} style={{ width: 'auto', height: '5vh'}} />
            </div>
            <div>
                <img src={Godot} style={{ width: 'auto', height: '5vh'}} />
            </div>
            <div>
                <img src={Unity} style={{ width: 'auto', height: '5vh'}} />
            </div>
        </Slider>
    );
}

export default function About() {
    const container = useRef();

    const { scrollYProgress } = useScroll({
        target: container,
        offset: ["start end", 'end start']
    })

    const y = useTransform(scrollYProgress, [0, 1], ["-5vh", "20vh"]);

    return (
        <div ref={container} className='bg-[#F5EFE6] min-h-screen flex-grow flex flex-col mb-24' id="about">
            <motion.div style={{y}} className="flex-grow flex flex-col text-[#1A2130]">
                <div className="font-bold xs:px-20 pt-20 text-center xxs:text-4xl xs:text-6xl sm:text-7xl md:text-8xl lg:text-8xl">
                    about me
                </div>
                <div className="flex xxs:flex-col xl:flex-row w-full xxs:px-8 xs:px-20 sm:pt-16 pb-8 md:px-20">
                    <div className="flex xxs:flex-col lg:flex-row xl:flex-col lg:justify-between xl:justify-start text-[#1A2130] xxs:pt-8 xs:pt-16 sm:pt-0 lg:pt-4 xl:pt-16 xl:pr-48">
                        <div className="font-bold text-left xxs:text-3xl xs:text-5xl sm:text-6xl md:text-7xl xl:text-8xl lg:px-8">
                            <AnimText phrase={'Hello'} />
                        </div>
                        <div className="font-bold text-left xxs:text-3xl xs:text-5xl sm:text-6xl md:text-7xl xl:text-8xl lg:px-8">
                            <AnimText phrase={'Kamusta'} />
                        </div>
                        <div className="font-bold text-left xxs:text-3xl xs:text-5xl sm:text-6xl md:text-7xl xl:text-8xl lg:px-8">
                            <AnimText phrase={'Bonjour'} />
                        </div>
                    </div>
                    <div className="flex-grow flex flex-col text-[#1A2130] md:py-16 xs:py-8 xxs:py-4">
                        {/* <div className="text-4xl font-normal text-left pb-4"> */}
                        <div className="font-normal text-left xxs:pb-2 xs:pb-4 xs:text-2xl sm:text-3xl lg:text-4xl">
                            I am Millaizha Airabellha "<span className="font-bold">Mill</span>" Valencia, a <span className="font-bold">Computer Science</span> student in the <span className="font-bold">University of the Philippines - Los Baños</span>.  
                        </div>
                        <div className="font-normal text-left xxs:py-2 xs:py-4 xs:text-2xl sm:text-3xl lg:text-4xl">
                            I am interested in blockchain, cybersecurity, game development, and social benefit as I am driven by a desire to improve the lives of uninformed and economically disadvantaged people in my country.
                        </div>
                        <div className="font-normal text-left xxs:py-2 xs:py-4 xs:text-2xl sm:text-3xl lg:text-4xl">
                            In my free time, I enjoy building Lego sets and designing mechanical keyboards from scratch.
                        </div>
                    </div>
                </div>
                <Contact />
            </motion.div>
            <ScrollToTop showUnder={5000} duration={2500}>
                    <div className="fixed bottom-16 xxs:right-12 md:right-16 xxs:scale-50 xs:scale-75 md:scale-100 bg-gray-800 text-white p-10 rounded-full cursor-pointer hover:bg-[#102C57] transform transition duration-500 xs:hover:scale-90 md:hover:scale-110">
                    <FaArrowUp style={{ fontSize: '2rem' }}/>
                    </div>
                </ScrollToTop>
        </div>
    );
}

