import { useInView, motion } from 'framer-motion';
import { useRef, useState, useEffect, useContext } from 'react';
import { LoadContext } from '../LoadContext';

export default function AnimText({ phrase, delay = 0 }) {
    const [hasAnimated, setHasAnimated] = useState(false);
    const description = useRef(null);
    const isInView = useInView(description);
    const isLoaded = useContext(LoadContext);

    useEffect(() => {
        if (isInView && isLoaded && !hasAnimated) {
            setHasAnimated(true);
        }
    }, [isInView, isLoaded, hasAnimated]);

    const slideUp = {
        initial: {
            y: "100%",
            opacity: 0
        },
        open: (i) => ({
            y: "0%",
            opacity: 1,
            transition: { duration: 0.75, delay: delay + i * 0.05 }
        }),
        closed: {
            y: "100%",
            opacity: 0,
            transition: { duration: 0.75 }
        }
    };

    return (
        <div ref={description} className="description">
            <div className="body">
                <p>
                    {phrase.split(' ').map((word, index) => (
                        <span key={index} className="mask">
                            <motion.span
                                variants={slideUp}
                                initial="initial"
                                animate={hasAnimated ? "open" : "closed"}
                                custom={index}
                            >
                                {word}
                            </motion.span>
                            {(index < phrase.split(' ').length - 1) && <span>&nbsp;</span>}
                        </span>
                    ))}
                </p>
            </div>
        </div>
    );
}
