export default function Project({ project, onHover, onLeave}) {
    return (
        <div key={project.id} className='flex flex-col w-full md:w-[60vw] xxs:pt-16 xs:py-8 justify-start min-w-[60vw] xxs:px-2 xs:px-4 xl:px-24'>
            <a href={project.link} target="_blank" rel="noopener noreferrer" onMouseEnter={onHover} onMouseLeave={onLeave}>
                <div className='p-8 flex items-center rounded-lg' style={{ backgroundImage: `url(${project.backgroundImage})`, backgroundSize: 'cover' }}>
                    <video src={project.video} alt={project.title} className='w-full rounded-3xl' loop autoPlay muted />
                </div>
            </a>
            <div className='w-full py-4'>
                <div className='text-xl font-normal text-[#F5EFE6] pb-2'>
                    {project.description}
                </div>
                <div className='flex flex-grow text-4xl font-bold text-[#F5EFE6]'>
                    {project.title}
                </div>
                <div className='w-full flex flex-row md:flex-row justify-between flex-grow md:space-x-8 py-4'>
                    <div className="flex flex-col justify-end mt-4 md:mt-0">
                        <div className="flex flex-row space-x-4 justify-normal text-sm font-medium " style={{ whiteSpace: 'nowrap' }}>
                            <div className='py-1 px-3 border rounded-2xl text-[#F5EFE6]'>
                                {project.category1}
                            </div>
                            <div className='py-1 px-3 border rounded-2xl text-[#F5EFE6]'>
                                {project.category2}
                            </div>
                            <div className='py-1 px-3 border rounded-2xl bg-[#F5EFE6] text-[#1A2130]'>
                                {project.year}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}