import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import '../App.css'; // Assuming you have a CSS file for styling

const words = ["welcome to mill's portfolio"];

export default function Preloader() {
    const [index, setIndex] = useState(0);
    const [dimension, setDimension] = useState({ width: 0, height: 0 });
    const [showLoadingCircle, setShowLoadingCircle] = useState(false);

    useEffect(() => {
        setDimension({ width: window.innerWidth, height: window.innerHeight });
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowLoadingCircle(true);
        }, 2000);

        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        if (index === words.length - 1) return;
        const timeout = setTimeout(() => {
            setIndex(index + 1);
        }, index === 0 ? 1000 : 250);

        return () => clearTimeout(timeout);
    }, [index]);

    const opacity = {
        initial: {
            opacity: 0,
        },
        enter: {
            opacity: 0.75,
            transition: { duration: 1, delay: 0.2, ease: 'easeOut' }, // Adding ease
        },
    };

    const slideUp = {
        initial: {
            top: 0,
        },
        exit: {
            top: '-100vh',
            transition: { duration: 0.8, ease: [0.76, 0, 0.24, 1], delay: 0.2 },
        },
    };

    const initialPath = `M0 0 L${dimension.width} 0 L${dimension.width} ${dimension.height} Q${dimension.width / 2} ${dimension.height + 300} 0 ${dimension.height}  L0 0`;
    const targetPath = `M0 0 L${dimension.width} 0 L${dimension.width} ${dimension.height} Q${dimension.width / 2} ${dimension.height} 0 ${dimension.height}  L0 0`;

    const curve = {
        initial: {
            d: initialPath,
            transition: { duration: 0.7, ease: [0.76, 0, 0.24, 1] },
        },
        exit: {
            d: targetPath,
            transition: { duration: 0.7, ease: [0.76, 0, 0.24, 1], delay: 0.3 },
        },
    };

    return (
        <motion.div variants={slideUp} initial="initial" exit="exit" className="introduction xxs:px-12 text-center md:p-0 flex-col">
            {dimension.width > 0 && (
                <>
                    <motion.p variants={opacity} initial="initial" animate="enter">{words[index]}</motion.p>
                    <svg>
                        <motion.path variants={curve} initial="initial" exit="exit"></motion.path>
                    </svg>
                    {showLoadingCircle && (
                        <motion.div
                            className="loading-spinner"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1, transition: { duration: 1, delay: 0.2, ease: 'easeOut' } }}
                        ></motion.div>
                    )}
                </>
            )}
        </motion.div>
    );
}
