import React, { createContext, useState, useEffect } from 'react';

export const LoadContext = createContext();

export const LoadProvider = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const handleLoad = () => {
      setIsLoaded(true);
    };

    window.addEventListener('load', handleLoad);

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  return (
    <LoadContext.Provider value={isLoaded}>
      {children}
    </LoadContext.Provider>
  );
};
