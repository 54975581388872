import Anchor from './anchor';
import { useScroll, useTransform, motion } from 'framer-motion';
import { useRef } from 'react';
import AnimText from './text';


export default function Home() {
    const container = useRef();
    const { scrollYProgress } = useScroll({
        target: container,
        offset: ['start start', 'end start']
    });

    const y = useTransform(scrollYProgress, [0, 0.8], ["0vh", "550vh"]);
    const scale = useTransform(scrollYProgress, [0, 1], [1, 0.5]);
    const opacity = useTransform(scrollYProgress, [0, 0.3], [1, 0]);

    return (
        <div className='bg-[#F5EFE6] min-h-screen flex flex-col overflow-hidden' id='home'>
            <Anchor />
            <motion.div style={{ y, scale, opacity }} className="flex-grow flex flex-col xxs:px-12 xs:px-20 xxs:pt-2 lg:pt-0 text-[#1A2130]">
                <div className="font-bold xxs:text-4xl xs:text-6xl sm:text-7xl md:text-8xl xl:text-9xl">
                    <AnimText phrase={'Hey'} delay={0.2} />
                </div>
                <div className="font-bold flex flex-row text-[#1A2130] xxs:text-4xl xs:text-6xl sm:text-7xl md:text-8xl xl:text-9xl">
                    <AnimText phrase={"I'm Mill"} delay={0.65} />
                </div>
                <div className="font-light xl:pl-[32rem] xxs:py-4 md:py-8 text-[#1A2130] xs:text-3xl sm:text-4xl md:text-5xl xl:text-6xl">
                    <AnimText delay={1} phrase={'— a Computer Science student who is interested in cybersecurity and game development.'} />
                </div>
            </motion.div>
        </div>
    );
}
