import Project from "./project"
import bg1 from '../assets/bg1.JPEG'
import bg2 from '../assets/bg2.JPEG'
import bg3 from '../assets/bg3.JPEG'
import Navbar from './navbar';
import AnimText from './text';
import { motion, useTransform, useScroll } from "framer-motion";
import { useEffect, useRef } from "react";
import Lenis from "lenis";

const projects = [
    {
        id: 1,
        backgroundImage: bg2,
        video: 'https://res.cloudinary.com/da4joy73z/video/upload/v1718102534/proj1_c9wpx3.mp4',
        title: 'Agri Dash',
        description: 'Action Adventure',
        category1: '3D DESIGN',
        category2: 'GODOT ENGINE',
        year: '2024',
        link: 'https://isenpachi.itch.io/agri-dash',
    },
    {
        id: 2,
        backgroundImage: bg1,
        video: 'https://res.cloudinary.com/da4joy73z/video/upload/v1718102619/proj2_newcbv.mp4',
        title: 'That Time I Woke Up as A Cockroach, but I\'m the Breadwinner',
        description: 'Comedy Simulation',
        category1: '3D DESIGN',
        category2: 'GODOT ENGINE',
        year: '2024',
        link: 'https://yuna-kulit.itch.io/that-time-i-woke-up-as-a-cockroach-but-im-the-breadwinner',
    },
    {
        id: 3,
        backgroundImage: bg3,
        video: 'https://res.cloudinary.com/da4joy73z/video/upload/v1718102561/proj3_yog0ot.mp4',
        title: 'Nettoyage Des Marees / Tidal Tidying',
        description: 'Adventure Platformer',
        category1: '2D DESIGN',
        category2: 'GODOT ENGINE',
        year: '2023',
        link: 'https://isenpachi.itch.io/tidal-tidying',
    }
]

const HorizontalScrollCarousel = ({ onHover, onLeave }) => {

    const targetRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: targetRef,
    });

    const x = useTransform(scrollYProgress, [0, 1], ["1%", "-70%"]);

    return (
        <section ref={targetRef} className="relative h-[180vh]">
        <div className="sticky top-0 flex h-screen items-center overflow-hidden">
            <motion.div style={{ x }} className="flex gap-4">
            {projects.map((project) => {
                return <Project project={project} key={project.id} onHover={onHover} onLeave={onLeave}/>;
            })}
            </motion.div>
        </div>
        </section>
    );
};

export default function Works({ onHover, onLeave }) {
    useEffect(() => {
        const lenis = new Lenis();
        
        function raf(time) {
            lenis.raf(time);
            requestAnimationFrame(raf);
        }
        
        requestAnimationFrame(raf);
        }, []);

    return (
        <div className='bg-[#1A2130] min-h-screen rounded-t-3xl flex-grow flex flex-col' id="works">
            
        <Navbar/>
            <div className="flex-grow flex flex-col py-16">
                {/* <div className="text-8xl font-bold text-[#F5EFE6] px-20"> */}
                <div className="font-bold text-[#F5EFE6] xxs:px-12 xs:px-20 xxs:text-3xl xs:text-6xl sm:text-7xl md:text-8xl lg:text-8xl">
                    <AnimText phrase={'selected works'} />
                </div>
                {/* <div className="text-4xl font-light text-[#F5EFE6] md:px-28 text-right"> */}
                <div className="xxs:text-2xl xs:text-4xl font-light text-[#F5EFE6] xxs:px-12 xs:px-20 pt-4 xl:px-28 xl:text-right ">
                <AnimText phrase={'Featured projects.'} />
                </div>
                <HorizontalScrollCarousel onHover={onHover} onLeave={onLeave}/>
            </div>
        </div>
    );
}